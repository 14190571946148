var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-fill mt-3"},[(_vm.cashPurchases.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 grey lighten-4 ",staticStyle:{"border-radius":"3px"}},[_c('span',{staticClass:" font font-size-md ml-2 mt-2 text-uppercase font-weight-medium"},[_vm._v("All Cash Purchases")]),_vm._l((_vm.cashPurchases),function(purchase,index){return _c('div',{key:index,staticClass:"d-flex flex-column flex-grow-1 my-1 white pa-2 ma-2 box-shadow-light"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 pl-3 pr-3 pt-5 pb-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"font font-weight-medium font-size-md",attrs:{"dense":"","items":_vm.suppliers,"item-value":"id","autofocus":"","outlined":"","return-object":"","item-text":"name","prepend-icon":"store","label":"Choose a supplier","error-messages":errors[0]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list',[_c('v-list-item',{attrs:{"dense":""}},[_c('span',{staticClass:"font font-weight-medium font-size-md"},[_vm._v("No suppliers' have been added yet. ")])])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font font-weight-medium font-size-md"},[_vm._v(_vm._s(item.name))])]}}],null,true),model:{value:(purchase.supplierId),callback:function ($$v) {_vm.$set(purchase, "supplierId", $$v)},expression:"purchase.supplierId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-grow-1",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font font-weight-medium font-size-md",attrs:{"outlined":"","label":"Total Balance/Amount","prepend-icon":"credit_card","prefix":_vm.currency && _vm.currency.length > 0 ? _vm.currency[0].symbol : 'GHS',"dense":"","error-messages":errors[0]},model:{value:(purchase.amount),callback:function ($$v) {_vm.$set(purchase, "amount", $$v)},expression:"purchase.amount"}})]}}],null,true)}),(_vm.salesPaymentOptions.length > 0)?_c('ValidationProvider',{staticClass:"d-flex flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"font font-weight-medium font-size-md",attrs:{"dense":"","outlined":"","items":_vm.salesPaymentOptions,"item-value":"id","item-text":"value","prepend-icon":"store","label":"Choose Source Of payment","error-messages":errors[0]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list',[_c('v-list-item',{attrs:{"dense":""}},[_c('span',{staticClass:"font font-weight-medium font-size-md"},[_vm._v("No suppliers' have been added yet. ")])])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font font-weight-medium font-size-md"},[_vm._v(_vm._s(item.value))])]}}],null,true),model:{value:(purchase.isPaymentMadeFromSalesAmount),callback:function ($$v) {_vm.$set(purchase, "isPaymentMadeFromSalesAmount", $$v)},expression:"purchase.isPaymentMadeFromSalesAmount"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"tag":"div"}},[_c('v-text-field',{staticClass:" font font-weight-medium font-size-md",attrs:{"outlined":"","dense":"","label":"Invoice or Payment No.","prepend-icon":"receipt_long","hint":"Invoice or payment number."},model:{value:(purchase.invoiceNumber),callback:function ($$v) {_vm.$set(purchase, "invoiceNumber", $$v)},expression:"purchase.invoiceNumber"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-1",attrs:{"icon":"","color":"red","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeCashPurchase(index)}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Remove Item")])])],1)])}),_c('div',{staticClass:"d-flex flex-row justify-end my-2 mr-2 mb-2 flex-grow-1"},[_c('v-btn',{attrs:{"disabled":_vm.totalSalesAmount <= _vm.totalAmountTallied && _vm.hasMDsAllocationTallied,"small":"","color":"primary"},on:{"click":_vm.addCashPurchase}},[_c('span',{staticClass:"font font-weight-medium font-size-sm",staticStyle:{"font-size":"12px !important"}},[_vm._v(" Add Cash Purchase ")])])],1)],2):_c('div',{staticClass:"d-flex flex-column flex-grow-1 my-1 justify-center grey lighten-4 pa-5",staticStyle:{"align-items":"center"}},[_c('img',{staticStyle:{"width":"80px"},attrs:{"src":require("@/assets/shopping-bag.svg")}}),_c('span',{staticClass:"font font-weight-medium"},[_vm._v("Record all cash purchases made on "),_c('kbd',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.date))]),_vm._v(" shop activities or operations")]),_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Cash purchases are goods acquired from a supplier and paid with cash")]),_c('v-btn',{staticClass:"mt-2",attrs:{"disabled":_vm.totalSalesAmount <= _vm.totalAmountTallied && _vm.hasMDsAllocationTallied,"color":"primary"},on:{"click":_vm.addCashPurchase}},[_c('span',{staticClass:"font font-weight-medium font-size-sm",staticStyle:{"font-size":"12px !important"}},[_vm._v("Add Cash Purchase")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }